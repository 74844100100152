.login-logo img {
  width: 70px;
  margin-right: 15px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.login-wrapper > div {
  width: 100%;
  max-width: 500px;
}