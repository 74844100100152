.menu_list_item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu_list_item:hover {
    background: #26304612;
}

.project_color {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

span.project_label {
    color: #111827;
}
.menu_list_item.active {
    background: var(--sidebar-color);
}

.menu_list_item.active span.project_label {
    color: #fff;
}

.table-action .dropdown-toggle:after {
    display: none;
}

.table-action .dropdown-toggle {
    font-size: 21px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 9px;
}

.table-action .dropdown-item {
    padding: 7px 14px;
    font-size: 0.9em;
    font-weight: 500;
}

.priority-levels .dropdown-toggle-app,
.priority-levels .dropdown-toggle-app:hover,
.priority-levels .dropdown-toggle-app:focus {
  color: #fff;
}

.priority-levels .dropdown-toggle-app:after {
  display: none;
}

.priority-levels .dropdown-toggle-app {
  padding: 5px 14px;
  border-radius: 7px;
  background-color: rgba(var(--color), 0.1);
  color: rgb(var(--color));
  cursor: default;
  font-weight: 500;
  text-align: center;
}

.priority-levels .dropdown-toggle-app:hover,
.priority-levels .dropdown-toggle-app:focus,
.priority-levels .dropdown-toggle-app:active {
  color: rgb(var(--color));
  background-color: rgba(var(--color), 0.1);
}

form.projectSelectorForm input {
  background: transparent;
  border: 0;
  width: 100%;
  padding: 0 10px;
  outline: none;
}

form.projectSelectorForm button {
  border-radius: 40px;
}

form.projectSelectorForm {
  background: #f3f3f3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
}

form.projectSelectorForm:focus-within {
  border-color: #ede1dd;
  box-shadow: 0 0 0 0.25rem rgb(221 176 158 / 25%);
}