.project_name:hover,
.project_name:active {
  border-color: #ede1dd;
  box-shadow: 0 0 0 0.25rem rgb(221 176 158 / 25%);
}

.attachmentButton {
  margin-top: 20px;
  padding: 6px 14px;
  border-radius: 6px;
  background: #f9f9f9;
  width: fit-content;
  border: 1px solid #dddddd;
  cursor: pointer;
}

.attachmentButton:hover {
  background: #ededed;
}


.files-selected > div button {
    border: 0;
    font-size: 11px;
    border-radius: 10px;
    padding: 3px;
    margin-left: 15px;
    display: flex;
}

.files-selected > div button:hover {
    background: #dfdfdf;
}

.files-selected > div span {
  margin-right: 5px;
}

.files-selected > div {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 11px;
  border: 1px solid #cbcbcb;
}

.files-selected {
    border-top: 1px solid #f1f0f2;
    margin-top: 15px;
    padding-top: 15px;
}

.files-selected {
    border-top: 1px solid #f1f0f2;
    margin-top: 15px;
    padding-top: 15px;
    display: flex;
    column-gap: 7px;
    flex-wrap: wrap;
    row-gap: 7px;
}
