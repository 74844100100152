.Four0Four h1 {
  font-size: 7em;
  font-weight: 900;
  margin-bottom: 40px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: url(images/galaxy.jpg);
}

.Four0Four {
  background: #fff;
  width: 730px;
  margin: 30px auto;
  text-align: center;
  padding: 60px 20px;
  border-radius: 10px;
}

.Four0Four .goHomeButton {
  border: 0;
  background: #111827;
  color: #fff;
  padding: 8px 30px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 7px;
}

.Four0Four .goHomeButton:hover {
  opacity: 0.8;
}