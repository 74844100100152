.logo-container {
    width: var(--sidebar-width);
    background: #111827;
    position: absolute;
    left: 0;
    height: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #232d42;
}
  
  .logo-container img {
    height: calc(var(--header-height) - 18px);
  }

.sidebar .menu {
    --distance: 20px;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: calc(var(--header-height) + var(--distance));
    padding: 0;
    height: calc(100% - var(--header-height) - var(--distance));
}

.sidebar .menu li {
    padding: 3px 8px;
    display: flex;
}

.sidebar .menu li a {
    width: 100%;
    padding: 10px;
    font-weight: 500;
    text-decoration: none;
    padding-left: 18px;
    display: flex;
    align-items: center;
    color: #fff;
    border-radius: 10px;
}

.sidebar .menu li a svg {
    margin-right: 10px;
    font-size: 22px;
}

.sidebar .menu li a:hover {
    background: #1c2436;
}

.sidebar .menu li a.active {
    background: #263046;
}

.sidebar .menu li.quick-add {
    justify-content: center;
    border-top: 1px solid #232d42;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sidebar .menu .quick-add a,
.sidebar .menu .quick-add a.active {
    justify-content: center;
    background: #e8280b;
    width: 77% !important;
}

.sidebar .menu .quick-add a:hover {
    background-color: #b52411;
}

.profile-wrapper .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-left: 0;
    border-radius: 40px;
}

.notification-bell:hover,
.profile-wrapper .dropdown-toggle:hover {
    background-color: #f1f1f1 !important;
}

.profile-wrapper .dropdown-toggle .AuthPhoto {
    width: 40px;
    height: 40px;
    background-size: cover !important;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 50%;
}

.notification-bell {
    font-size: 27px !important;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    position: relative;
}

.notification-bell:after {
    display: none !important;
}

.notification-bell:before {
    content: attr(data-count);
    position: absolute;
    top: -1px;
    right: 0px;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    background: #e2191c;
    padding: 0 5px;
    border-radius: 30px;
}

.notification-list-menu {
    min-height: 576px;
    max-height: 576px;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
}

.linkCount {
    font-size: 12px;
    background: #f2f2f4;
    padding: 1px 6px;
    border-radius: 3px;
    font-weight: bold;
    color: #111827;
}

.profile-content .show.dropdown .dropdown-toggle,
.profile-wrapper .show.dropdown .dropdown-toggle {
  background-color: #f1f1f1 !important;
}

.back_forward {
  margin-right: 10px;
  display: flex;
}

.back_forward button {
  background: var(--bs-btn-hover-bg);
  display: flex;
  padding: 0;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 2px;
  align-items: center;
  justify-content: center;
  font-size: 21px;
}