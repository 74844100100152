.ql-editor {
    min-height: 200px;
}

.priority-button > label {
    padding: 6px 15px;
    border-radius: 20px;
    border: 1px solid transparent;
    margin-right: 7px;
    cursor: pointer;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 0.9em;
}

.priority-button > label.active {
    background-color: var(--color);
    color: #fff;
}

.priority-button > label.active svg {
  color: #fff !important;
}

.priority-button {
    display: flex;
}

ul.todo_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.todo_list li {
  margin-bottom: 5px;
}

ul.todo_list button, 
ul.todo_list input {
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 7px;
  background: #f4f4f4;
}

ul.todo_list input {
  margin-right: 5px;
}

ul.todo_list input:focus {
  box-shadow: none;
  border: 1px solid var(--bs-primary);
}

ul.todo_list button:last-child {
  width: 30px;
  height: 30px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #e1e1e1;
  border-radius: 30px;
}

ul.todo_list button {
    color: #b3b3b3;
}

.todo-items-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.todo-items-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.todo-items-exit {
  opacity: 1;
  transform: translateY(0);
}
.todo-items-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 500ms, transform 500ms;
}

ul.todo_list li.dragover {
    border: 1px dashed #979797;
    opacity: 0.3;
}

button.task_due_date {
    border: 0;
    padding: 5px;
    border-radius: 7px;
    color: #111;
    outline: none;
}

button.task_due_date:hover {
    background: ##f7f7f7;
}

.project-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.task_details table tr td:first-child {
    color: #878787;
    font-size: 0.95em;
    width: 107px;
    font-weight: 400;
    vertical-align: top;
}

.task_details table td {
    padding: 11px 0;
}

.task_details table tr:not(:last-child) td {
  border-bottom: 1px solid #00000014;
}

.task_details table td  .notes {
    padding: 0 10px;
}

ul.todo-nav a {
    padding: 4px 10px;
    text-decoration: none;
    color: #111827;
    font-size: 0.9em;
    opacity: 0.7;
}

ul.todo-nav a.active {
    opacity: 1;
}

ul.todo-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

.completed_button {
    --color: #4caf50;
    background-color: var(--color) !important;
    color: #fff !important;
    border: 1px solid !important;
    border-radius: 50% !important;
    height: 35px;
    width: 35px;
    padding: 0 !important;
}

.time_entry_button {
    --color: #e39b9b;
    background-color: var(--color) !important;
    color: #fff !important;
    border: 1px solid !important;
    border-radius: 50% !important;
    height: 35px;
    width: 35px;
    padding: 0 !important;
}

.button-wrapper-task-activator {
    padding: 5px 10px;
    padding-left: 5px;
    border-radius: 5px;
    color: #878787;
    border: 0;
    background: transparent;
}

.button-wrapper-task-activator:hover {
    background: #fdf5f5;
}

.time_entry {
    font-size: 1.2em;
    color: #e2191c;
}

.task_header button {
    color: #111827 !important;
    font-weight: 500;
}

.task_header .time_entry_header {
    font-weight: 400;
    padding-right: 9px;
    color: #878787;
}

.todo-accordion {
    --bs-accordion-border-color: var(--body-bg-darker) !important;
}

ul.task-tabs {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

ul.task-tabs li a {
    background: #f8f9f9;
    display: flex;
    margin-right: 3px;
    padding: 4px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 600;
}

ul.task-tabs li a.active {
    background: #263046;
    color: #fff;
}

.todo-item {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;
    border-radius: 7px;
    color: var(--primary-color);
}

.todo-item:hover {
    background: #e7e7e91e;
}

.todo_status {
    padding: 0 8px;
    border: 1px solid;
    margin-left: 10px;
    color: green;
}

.task--details-overlay {
  background: rgb(0 0 0 / 12%);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task--details {
  background: #fff;
  width: 100%;
  height: 100%;
}

.task-transition-enter .task--details {
  transform: translate(600px);
  opacity: 0;
}

.task-transition-enter-active .task--details {
  transform: translate(0);
  opacity: 1;
  transition: transform 200ms, opacity 200ms;
}

.task-transition-exit .task--details {
  opacity: 1;
  transform: translate(0);
}
.task-transition-exit-active .task--details {
  opacity: 0;
  transform: translate(600px);
  transition: transform 200ms, opacity 200ms;
}

.checkmark-circle {
    width: 25px;
    height: 25px;
    --border-color: darkgrey;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    cursor: pointer;
}

.checkmark-circle:hover {
  opacity: 0.6;
}

.checkmark-circle .checkmark {
    height: 6px;
    width: 10px;
    transform: rotate(-43deg);
    margin-top: -3px;
    margin-left: 6px;
    position: relative;
}

.checkmark-circle .checkmark:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-width: 1px;
    border-color: var(--border-color);
    border-bottom-style: solid;
}

.checkmark-circle .checkmark:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    border-width: 1px;
    border-color: var(--border-color);
    border-left-style: solid;
}

.checkmark-circle.checked {
  --border-color: #fff;
  background-color: #4CAF50;
}

.checkmark-circle.checked .checkmark:after {
  width: 100%;
  transition: width 200ms;
}

.checkmark-circle.checked .checkmark:before {
  height: 100%;
  transition: height 200ms;
}

.todo-nav .add-todo {
  height: 37px;
  width: 37px;
}

.todo-item-enter {
  opacity: 0;
}
.todo-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.todo-item-exit {
  opacity: 1;
}
.todo-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.todo-list-container:hover::-webkit-scrollbar-thumb {
  background-color: rgb(238, 238, 238);
}

.todo-list-container::-webkit-scrollbar {
  width: 0.3em;
}
 
.todo-list-container::-webkit-scrollbar-track {
  background-color: transparent;
}
 
.todo-list-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.existing-task-main {
  width: 100%;
  max-width: 550px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  /* --box-shadow: 1px 2px 30px #00000012; */
}

.create-new-task {
  background: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 7px;
  box-shadow: var(--box-shadow);
  font-weight: bold;
  font-size: 1.2em;
  color: #6c757d;
}

.create-new-task:hover {
  background: #e8280b;
  color: #fff;
  transition: all 100ms;
}

.existing-task-main .separator {
  margin: 10px 0;
  text-align: center;
  font-size: 1.07em;
  text-transform: uppercase;
  font-weight: 500;
  color: #8b8b8b;
}

.existing-task {
  background-color: #fff;
  padding: 20px;
  box-shadow: var(--box-shadow);
  border-radius: 7px;
}

.existing-task-container > ul {
  padding: 0;
  margin: 0;
}

.existing-task-container > ul li {
  --border-color: #efefef;
  margin-bottom: 1px;
  padding: 9px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
}

.existing-task-container > ul li:first-child {
  border-top: 1px solid var(--border-color);
}

.existing-task-container > ul li .existing-task-name {
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 5px;
}

.existing-task-container > ul li:hover {
  background: #f9f9f9;
}

.existing-task-container > ul li .chevron {
  transition: transform 300ms;
  transform: translateX(-5px);
}

.existing-task-container > ul li:hover .chevron {
  transform: translateX(0);
}

.existing-task-container > ul li .existing-task-priority {
  border: 1px solid;
  width: fit-content;
  padding: 0px 6px;
  font-size: 0.8em;
  color: var(--color);
  font-weight: 500;
}

.existing-task-main-enter {
  opacity: 0;
  transform: translateY(-400px);
}

.existing-task-main-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.existing-task-main-exit {
  opacity: 1;
}
.existing-task-main-exit-active {
  opacity: 0;
  transform: translateY(-400px);
  transition: opacity 300ms, transform 300ms;
}


.createform-transition-enter {
  opacity: 0;
  transform: translateY(100px);
}

.createform-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.createform-transition-exit {
  opacity: 1;
}
.createform-transition-exit-active {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 300ms, transform 300ms;
}

.user_task_details img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.user_task_details {
  width: fit-content;
  padding: 8px;
  border-radius: 5px;
}

.user_task_details:hover {
  background: #f7f7f7;
}

.todo-nav button {
    --color: #0f1522;
    padding: 5px 6px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    margin-right: 9px;
    color: var(--color);
    background: transparent;
    font-size: 0.95em;
}

.todo-nav button.active {
    border-color: var(--color);
}

.todo-create-form {
    border: 1px solid #f1f0f2;
    border-left-color: transparent;
    border-right-color: transparent;
}
.todo-create-form input {
    border-color: transparent;
}

.todo-create-form input:focus {
    box-shadow: none;
    border: none;
}

.ticketViewNav .dropdown-toggle,
.ticketViewNav .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.ticketViewNav .dropdown-toggle:after {
    display: none;
}

.task-status {
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500  !important; 
    padding: 3px 12px;
    border: 1px solid !important;
}

.task-status + button {
  border: 1px solid !important;
}

.task-view-table input {
    padding: 8px;
    border: none;
    width: 100%;
    border-radius: 5px;
    background: #f7f7f7;
}

.task-view-table input:focus {
    outline: none;
}

.task-view-table .form-select-tag {
    width: auto;
}

.task-view-table .form-select-tag span + div {
    border-color: transparent;
    height: auto;
    background: #f7f7f7;
}

.task-view-table .form-select-tag:focus-within span + div {
    background: #f7f7f7;
    border-color: transparent;
}

.task-view-table .form-select-tag .css-1okebmr-indicatorSeparator {
    display: none;
}

.task-details-view > div {
  display: flex;
  flex-direction: column;
}

.task-name label {
  color: #5b627c;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 0.9em;
}
.task-name input {
  border: 0;
  padding: 8px 10px;
  font-weight: 500;
  border-radius: 6px;
}
.task-name {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 7px;
}

.taskListWrapper {
  height: 500px;
  margin-bottom: 1.5em;
  background: #fff;
  padding: 20px;
}

.taskListWrapperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--color);
  color: var(--color);
  padding-bottom: 10px;
}

.taskListWrapperIcon {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background-color: #dbdce1;
  color: #111;
}

.taskListWrapperBody table {
  border: none;
}

.taskListWrapperBody table td {
  padding: 13px 15px;
}

.taskListWrapperBody table tbody tr:hover td {
  background-color: #f9f9f9;
}

.taskListWrapperBody {
  padding: 10px 0;
}

.task_list_table thead td,
.task_list_table thead th,
.task_list_table tbody td {
  padding: 17px 16px;
  position: relative;
}

.task_list_table tbody tr td{
  border: none;
} 

.task_list_table tbody tr.unread td {
  background-color: #f3f3f3;
}


.task_list_table tbody tr.waitingForAssignUser td {
  background: #fceded;
}

.StatusList {
  background: rgba(var(--color));
  color: #111;
  padding: 4px 10px;
  border-radius: 40px;
  border: 1px solid rgba(var(--color), 0.2);
  font-size: 0.9em;
}

.project_name button {
  border: 0;
  padding: 1px 4px;
  font-size: 11px;
  border-radius: 50%;
  background-color: transparent;
}

.project_name button:hover {
  background-color: whitesmoke;
}

.taskListRow {
  position: relative;
  z-index: 1;
}

.taskListRow.notice:after {
  --outline: 255, 193, 7;
  height: 80%;
  transform: translate(0, 14%);
  box-shadow: 0 0 0 rgb(var(--outline), 0.1);
  animation: pulse 1s infinite;
  border: 1px solid rgb(var(--outline), 0.5);
}  

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(var(--outline), 0.4);
    box-shadow: 0 0 0 0 rgb(var(--outline), 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgb(var(--outline), 0);
      box-shadow: 0 0 0 10px rgb(var(--outline), 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgb(var(--outline), 0);
      box-shadow: 0 0 0 0 rgb(var(--outline), 0);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(var(--outline), 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgb(var(--outline), 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgb(var(--outline), 0.4);
  }
}

.taskListRow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 150ms;
}

.taskListRow:not(:last-child):after {
  border-bottom: 1px solid #f1f1f1;
}

.taskListRow:not(.notice):hover:after {
  background: #f1f1f1;
}

tr.headingBg {
  position: relative;
  z-index: 1;
}

tr.headingBg.ticket:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86%;
  z-index: -1;
  border-bottom: 1px solid #fdeded;
}

tr.headingBg.task:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86%;
  z-index: -1;
  border-bottom: 1px solid #fdeded;
}

tr.headingBg td {
  border: none;
}

.newBadge {
  color: #fff;
  background: var(--bs-blue);
  font-size: 0.8em;
  font-weight: 100;
  padding: 2px 7px;
  border-radius: 4px;
  margin-left: 10px;
  width: fit-content;
}

span.sortTable:hover {
  color: #111;
}