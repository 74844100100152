:root {
  --header-height: 72px;
  --sidebar-width: 270px;
  --sidebar-color: #111827;
  --warning-height: 0px;
  --body-bg: #d9d9d9f0;
  --body-bg-darker: #f1f0f2;
  --bs-border-color: var(--body-bg-darker) !important;
  --input-border-shadow-opacity: 0.25;
  --input-border: rgba(237, 225, 221, var(--input-border-shadow-opacity));
}

html {
  font-size: 15px;
}

body {
  background: var(--body-bg);
  /* font-family: acumin-pro, sans-serif; */
}

.App {
  display: flex;
}

header.App-header {
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
  z-index: 3;
  position: fixed;
  left: 0;
  top: var(--warning-height);
  box-shadow: 0 0 11px #0000000d;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.Header-Right {
  width: calc(100% - var(--sidebar-width));
}

.sidebar {
  width: var(--sidebar-width);
  background-color: var(--sidebar-color);
  height: 100%;
  position: fixed;
  left: 0;
  top: var(--warning-height);
}

.page-manager {
  width: calc(100% - var(--sidebar-width));
  margin-top: calc(var(--header-height) + var(--warning-height));
  margin-left: var(--sidebar-width);
}

.page-manager .content {
  padding: 30px 20px;
}

label {
  font-weight: 500;
}

.form-control, .form-select-tag span + div {
  height: 43px;
  --input-border-shadow-opacity: 1;
  border-color: #e0e0e0;
}

.form-control::placeholder {
  color: lightgrey;
}

.fw-bold {
  font-weight: 600 !important;
}

.notification {
  height: var(--warning-height);
  overflow: hidden;
}

.box {
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  position: relative;
  border-radius: 10px;
}

.box .disabled-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
}

table.table thead th,
table.table thead td {
  color: #979595;
  font-weight: 400;
  font-size: 0.9em;
}

.dropdown-menu {
  box-shadow: 1px 2px 30px #0000002b;
  border: 0;
}

table {
  border-color: var(--body-bg-darker) !important;
}

.form-control:focus {
  border-color: #ede1dd;
  box-shadow: 0 0 0 0.25rem rgb(221 176 158 / 25%);
}

.app.btn-primary {
  --bs-btn-bg: #e2191c;
  --bs-btn-border-color: #e2191c;
  --bs-btn-hover-bg: #d50a0d;
  --bs-btn-hover-border-color: #d50a0d;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-bg: #c51518;
  --bs-btn-active-border-color: #c51518;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-bg: #d2696b;
  --bs-btn-disabled-border-color: #d2696b;
  --bs-btn-focus-shadow-rgb: 253,49,49;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: #f1f0f2;
}

.ql-toolbar.ql-snow {
  border-radius: 7px 7px 0 0;
}

.ql-container.ql-snow {
  border-radius: 0 0 7px 7px;
}

.quill:focus-within .ql-toolbar.ql-snow,
.quill:focus-within .ql-container.ql-snow
 {
  border-color: rgb(210, 210, 210);
}

.hwi_calendar .react-datepicker__triangle {
  display: none;
}

.react-datepicker__tab-loop > .react-datepicker-popper {
  z-index: 2;
}

.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9001;
  padding: 2px 15px;
  height: 100%;
  cursor: progress;
  background: rgb(0, 0, 0, 0.04);
}

.text-loading {
  position: fixed;
  z-index: 9002;
  padding: 2px 15px;
  color: #fff;
  top: 20px;
  left: 50%;
  background: #f5a614;
  transform: translate(-50%, 0);
  box-shadow: 1px 2px 30px #0000001c;
}

.globalLoader-enter .text-loading {
  opacity: 0;
}
.globalLoader-enter-active .text-loading {
  opacity: 1;
  transition: opacity 200ms;
}
.globalLoader-exit .text-loading {
  opacity: 1;
}
.globalLoader-exit-active .text-loading {
  opacity: 0;
  transition: opacity 200ms;
}

.table-task_details button.react-datepicker__close-icon:after {
  background: transparent;
  color: #6c757d;
  font-size: 1.25rem;
}