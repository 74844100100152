.project-user-view {
  display: flex;
  align-items: center;
}
.project-user-view ._dot {
  width: 10px;
  height: 10px;
  background: var(--color);
  border-radius: 3px;
  margin-right: 8px;
}

.createdBy-user-view {
  display: flex;
  align-items: center;
}

.createdBy-user-view .photo-user-view {
  background: var(--user-photo);
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
  z-index: 1;
  background-size: cover;
}

.createdBy-user-view .photo-user-view:before {
  content: '';
  background: #00000014;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.assignedTo-user-view {
  display: flex;
  align-items: center;
}

.assignedTo-user-view .photo-user-view {
  background: var(--user-photo);
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
  z-index: 1;
  background-size: cover;
}

.assignedTo-user-view .photo-user-view:before {
  content: '';
  background: #00000014;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.table-task_details td {
  padding: 15px 0;
  border-bottom: 1px solid #f1f0f2;
}

.table-task_details tr:last-child td {
  border-bottom: none;
}

.user-view_PriortyLevel {
  color: rgb(var(--color));
  border-radius: 7px;
  padding: 8px 10px;
  font-weight: 600;
  width: 100%;
  background: rgba(var(--color), 0.08);
}

/* .user-remarks {
  padding: 15px 19px;
} */

.user-remarks textarea {
  width: 100%;
  height: 165px;
  padding: 10px;
  border: 0;
  background: #f5f5f7;
  border-radius: 10px;
}

.user-remarks textarea:focus {
  outline: none;
}

.user-view-todo-status button {
  --color: #8C96AF;
  border: 1px solid;
  margin-left: 5px;
  padding: 3px 12px;
  font-size: 0.9em;
  border-radius: 50px;
  color: var(--color);
  background-color: transparent;
}

.user-view-todo-status button.active {
  color: #fff;
  background-color: var(--color);
}

.user-todo-wrapper .todo--wrapper {
  border-radius: 10px;
  margin-top: 0;
  align-items: center;
  padding: 2px 10px;
  margin-bottom: 10px;
  border: 1px solid #ebebeb;
  background: #333b9b00;
  font-weight: 600;
}

.user-todo-wrapper .todo--wrapper.completed {
  background-color: #cff5ce94;
  border: 1px solid #cff5ce;
  transition: all 200ms;
}

.user-todo-wrapper .todo--wrapper .todo-text {
  border-left: 1px solid #ebebeb;
  padding: 7px;
}

.user-todo-wrapper .todo--wrapper .checkmark-circle .checkmark:before {
  height: 100%;
  transition: height 200ms;
}

.user-todo-wrapper .todo--wrapper .checkmark-circle .checkmark:after {
  width: 100%;
}

.task_Status {
  padding: 4px 11px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 2px solid rgb(var(--color));
}

.isReviewTask button {
  border-radius: 7px;
  padding-left: 12px;
  padding-right: 12px;
}

.isReviewTask button.decline {
  color: #f34b4b;
  border: 1px solid;
}

.isReviewTask button.accept {
  background: #4caf50;
  color: #fff;
  margin-left: 5px;
}

.AdminViewTaskAssignedTo {
  border: 1px dashed #b3b3b3;
  padding: 4px 10px;
  border-radius: 7px;
}

.AdminViewTaskAssignedTo:hover {
  border-color: #6d6d6d;
}

.task_StatusAdmin {
  padding: 4px 11px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 2px dashed rgb(var(--color));
  width: 180px;
}

.StatusButton {
  border: 2px solid rgb(var(--color));
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 7px;
  font-weight: 500;
}

.StatusButton.active {
  background: rgb(var(--color));
  color: #fff;
}

.project-select-creator-view {
  width: 366px;
}

.project-user-view.select {
  padding: 10px 12px;
  border-radius: 7px;
}

.project-user-view.select-default {
  padding: 4px 10px;
  border-radius: 7px;
}

.project-user-view.select-default.canEdit {
  border: 1px dashed #b3b3b3;
}

.project-user-view.select-default:hover {
  border-color: #6d6d6d;
}

.project-user-view.select:hover,
.project-user-view.select.active {
  background: #ebebeb;
}

.user-view_PriortyLevel.creatorView {
  color: rgb(var(--color));
  border-radius: 7px;
  padding: 8px 10px;
  font-weight: 600;
  width: 100%;
  background: rgba(var(--color), 0.08);
}

.user-view_PriortyLevel.creatorView.select {
  width: 100%;
  margin-bottom: 7px;
  padding: 10px;
  border: 1px solid rgb(var(--color));
  color: rgb(var(--color));
}

.user-view_PriortyLevel.creatorView.select:last-child {
  margin-bottom: 0;
}

.user-view_PriortyLevel.creatorView.select:hover {
  background-color: #f2f2f2;
}

.user-view_PriortyLevel.creatorView.select.active {
  background: rgba(var(--color));
  color: #fff;
}

.user-view_PriortyLevel.creatorView.canEdit {
  border: 1px dashed rgb(var(--color));
  opacity: 0.8;
}

.user-view_PriortyLevel.creatorView.canEdit:hover {
  opacity: 1;
}

.ViewCreatorTicketDueDate {
  border: none;
  padding: 0;
  border-radius: 0;
  display: block;
}
 
.ViewCreatorTicketDueDate.canEdit {
  border: 1px dashed #b3b3b3;
  padding: 4px 10px;
  border-radius: 7px;
}

.ViewCreatorTicketDueDate.canEdit:hover {
  border-color: #6d6d6d;
}

.user-remarks_ {
  height: auto !important;
  padding: 0;
  display: grid !important;
  padding: 6px  !important;
  border-radius: 10px  !important;
  background: #f5f5f5 !important;
  border: 0  !important;
}

.user-remarks_::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.user-remarks_:focus-within {
  background-color: #e9eaec !important;
}

.user-remarks_ > textarea,
.user-remarks_::after {
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

.user-remarks_ textarea {
  width: 100%;
  border: 0;
  resize: none;
  background: transparent;
  outline: none;
  overflow: hidden;
  padding-right: 65px;
}

.user-remarks_ .add-button {
  border-radius: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.remarkContentComment {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid var(--bs-border-color);
}

.remarkContentCommentProfile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  z-index: 1;
}

.remarkContentCommentProfile:before {
  content: '';
  background: #00000014;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.remarkContentInfo {
  width: calc(100% - 40px);
}

.remarkContentUserName {
  font-size: 0.9em;
  font-weight: 500;
}

.remarkContentCommentDate {
  font-size: 0.9em;
}

.remarkContentComment:hover {
  background: #f6f6f6;
  transition: 0.3s ease;
}

.tagg {
  opacity: 0.7;
}

.remarkContentMessage {
  margin-top: 6px;
  border-radius: 7px;
  padding: 5px 10px;
  padding-left: 0;
}

.remarkContentComment {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid var(--bs-border-color);
}

.remarkContentComment:last-child {
  border-bottom: 0;
}

.todoAddForm {
  margin-bottom: 11px;
}

.todoAddForm .form-control {
  display: flex;
  justify-content: space-between;
  background: whitesmoke;
  border: 0;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  align-items: flex-start;
}

.todoAddForm .form-control:focus-within {
  background: #e9eaec;
}

.todoAddForm .form-control textarea,
.todoAddForm .form-control input {
  width: 100%;
  border: 1px solid transparent;
  background: transparent;
  outline: none;
}

.todoAddForm button {
  border-radius: 50px;
}

.editableTaskName input {
  font-size: 1.5em;
  font-weight: 500;
  padding: 5px 0;
  border: 1px solid transparent;
  width: 100%;
}

.updatedRemarks {
  position: relative;
  z-index: 1;
}

.updatedRemarks:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 2px solid #e2191c;
  border-radius: 10px;
}

.maskViewerTask:before {
  content: 'Loading...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffffc7;
  z-index: 2;
  border-radius: var(--bs-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.attached_files > span:nth-child(2) {
  color: #6a6a6a;
}
.attached_files .fileLogo {
    margin-right: 8px;
    font-size: 25px;
    margin-bottom: 5px;
    color: #7a7a7a;
}
.attached_files {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #6a6a6a;
}

.attached_files:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
}

.attached_files:hover {
  cursor: pointer;
}

.attached_files:hover > span:nth-child(2) {
  text-decoration: underline;
}

.attchmentData {
  display: flex;
}

.attchmentData > label {
    border: 0;
    padding: 3px 10px;
    display: flex;
    cursor: pointer;
}

.attchedFiles .deleteAttachButton:hover,
.attchmentData > label:hover {
  background: #e1e1e1;
}

.attchedFiles .deleteAttachButton {
    display: flex;
    margin-left: 10px;
    border: 0;
    padding: 2px;
    border-radius: 2px;
    background: transparent;
}
.attchedFiles {
    font-size: 11px;
    padding: 0 7px;
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    padding-right: 4px;
    color: #7e7e7e;
}

.attchmentData {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3px;
    row-gap: 3px;
}

.attachmentFiles {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    row-gap: 4px;
    padding-top: 12px;
}

.attachmentFiles > div {
  cursor: pointer;
}

.clickTrash {
    color: rgb(255, 62, 46, 0.46);
}

.clickTrash:hover {
    color: rgb(255, 62, 46);
}