.notif-avatar {
  background: var(--bg-color);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  color: #fff;
  font-weight: 500;
}

.notif-name {
  font-weight: 500;
}

.notification_item:hover {
  background-color: #f3f3f3;
}

.notification_item:active {
  --bs-dropdown-link-active-bg: #111;
}

.notification_item.unread {
  background: #f3f3f3;
}

.notif-created-at {
  font-size: 0.8em;
}

.unopen-indicator {
  position: absolute;
  right: 15px;
  top: 14px;
  width: 7px;
  height: 7px;
  background: var(--dot-color);
  border-radius: 50%;
}