.app-pagination ul {
  list-style: none;
  margin: 0;
}

.app-pagination ul li a {
  padding: 6px 13px;
  text-decoration: none;
  color: var(--text-muted);
  border-radius: 5px;
  font-weight: 500;
  margin-right: 4px;
}

.app-pagination ul li a:hover {
  background: #efefef;
}

.app-pagination ul li.active a {
  background-color: #e8280b;
  color: #fff;
}

.app-pagination ul li {
  display: flex;
}