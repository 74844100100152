.assign_task_overlay_content {
  background: white;
  height: 500px;
  max-width: 500px;
}

.user-list:not(.active):hover {
  background: #f5f5f5;
}

.user-list.active {
  background: #d4d4d4;
}

.user-list .image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  background-size: cover !important;
}

.user-list .image:before {
  content: '';
  position: absolute;
  background-color: #00000014;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.scrolled-content {
  padding-right: 5px;
}

.scrolled-content::-webkit-scrollbar {
  width: 0.5em;
}
 
.scrolled-content::-webkit-scrollbar-track {
  border-radius: 4px;
}
 
.scrolled-content::-webkit-scrollbar-thumb {
  background-color: rgb(220, 220, 220);
  border-radius: 4px;
}