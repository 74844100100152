.due_date_today {
  background: #fff;
  border-radius: 7px;
  min-height: 500px;
  overflow: hidden;
}

.blockTitle {
  border-radius: 7px 7px 0 0;
  padding: 20px 10px;
  border-bottom: 1px solid #f2f2f4;
}

.blockTitle .due_date_text {
  font-size: 1.1em;
}

.blockItem {
  padding: 10px 20px;
  cursor: pointer;
}

.blockItem:hover {
  background-color: #e9ebf5;
}

.DashboardTaskPriority {
  padding: 5px 12px;
  border-radius: 7px;
  color: rgb(var(--color));
  background-color: rgb(var(--color), 0.1);
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center;
}

.blockFooter {
  border-top: 1px solid #f3f3f3;
}

.DashboardTaskProject .__dot {
  height: 8px;
  width: 8px;
  background: var(--color);
  margin-right: 5px;
  border-radius: 2px;
}
.DashboardTaskProject {
  display: flex;
  align-items: center;
}

.dashboardContainer {
  width: 100%;
}




.headingDashboardTask {
  background: #f2f2f4;
  border-radius: 7px;
}

.dashboardCount {
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
  color: #fff;
}

.dashboardCount .headingText {
  font-size: 2em;
  font-weight: 600;
}

.dashboardCount p {
  font-size: 2em;
  font-weight: 600;
}

.dashboardCount.first {
  background: #111827;
  background: -webkit-linear-gradient(to right, #171f30, #20438d); 
  background: linear-gradient(to right, #171f30, #20438d);
}

.dashboardCount.second {
  background: #20438d;
  background: -webkit-linear-gradient(to right, #20438d, #4566ad); 
  background: linear-gradient(to right, #20438d, #4566ad);
}

.TaskContentBordered {
  background: #fff;
  --min-height: 576px;
  min-height: var(--min-height);
  border-radius: 5px;
}

.TaskContent {
  padding: 4px;
  border-radius: 8px;
}

/* .DashboardTable tbody tr:not(:first-child):hover {
  background-color: #e9ebf5;
} */

.noTaskTodayDiv {
  background: #f9f9fa;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.tickDateDashboard {
  padding: 3px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.tickDateDashboardContent {
  padding: 20px;
  border-radius: 7px;
  background: #ffff;
}