.color_dropdown .dropdown-toggle:after {
    display: none;
}

.color_dropdown .dropdown-toggle {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0;
}

.color-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    padding: 0px 12px;
}

.color-wrapper > a {
    width: calc(100% / 5);
    padding: 2px;
}
.color-wrapper > a:active,
.color-wrapper > a:hover     {
    background-color: transparent;
}

.color-list-button {
    width: calc(150px / 5);
    height: calc(150px / 5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.3em;
}

.color-list-button:hover {
    opacity: 0.8;
}